
        import * as module0 from './controllers/contact_form_controller.js';import * as module1 from './controllers/dropdown_controller.js';import * as module2 from './controllers/example_controller.js';import * as module3 from './controllers/language_controller.js';import * as module4 from './controllers/viewer_controller.js'
        const modules = {
            "./controllers/contact_form_controller.js": module0,
            "./controllers/dropdown_controller.js": module1,
            "./controllers/example_controller.js": module2,
            "./controllers/language_controller.js": module3,
            "./controllers/viewer_controller.js": module4,
        };
        export default modules;
      