import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  static targets = ["formName", "select"];

  initialize() {
    console.log("do it");
  }

  change(event) { 
    const value = event.target.value;
    this.formNameTarget.value = "contact_" + this.snakeCase(value); 
  }

  snakeCase(value) {
    return value.toLowerCase().replaceAll(" ", "_");
  }
}
