import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "all",
    "checkbox",
    "row",
  ];
  static values = {};

  initialize() {
  }

  connect() {
  }

  selectAll() {
    this.checkboxTargets.forEach((c) => { c.checked = true });
    this.filter();
  }

  selectOnly(event) {
    this.checkboxTargets.forEach((c) => { c.checked = false});
    this.clickCheckbox(event.params.lang);
    this.filter();
 }

  clickCheckbox(value) {
    this.checkboxTargets.forEach((c) => {
      if (c.value == value) {
        c.checked = true;
      }
    });
    this.filter();
  }

  pick() {
    this.filter();
  }

  filter() {
    const viewLanguages = this.checkboxTargets.filter((c) => c.checked ).map((c) => c.value);
    this.rowTargets.forEach((r) => {
      const languages = r.dataset.languages.split(",")
      if (viewLanguages.filter((l) => languages.includes(l)).length == 0) {
        r.classList.add("!hidden");
      } else {
        r.classList.remove("!hidden");
      }
    });
  }

}
