import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["spinner", "image", "buttons", "select"];
  static values = {
    url: String,
    pages: Number,
    currentPage: Number
  }

  initialize() {
    this.currentPage = 
      Math.min(Math.max(this.parsePage(), 1), this.pagesValue);
  }

  connect() {
    this.navigate(this.url())
  }

  parsePage() {
    const params = new URLSearchParams(window.location.search);
    const urlPage = parseInt(params.get("page"));
    if (isNaN(urlPage)) {
      return 1;
    } else {
      return urlPage;
    }
  }

  advancePage() {
    this.currentPage = Math.min(this.currentPage + 1, this.pagesValue);
  }

  recedePage() {
    this.currentPage = Math.max(this.currentPage - 1, 1);
  }

  url() {
    return this.urlValue+"?q=100&auto=format&w=1214&page=" + this.currentPage;
  }

  replaceUrl() {
    return window.location.origin + 
      window.location.pathname +
      "?page=" +
      this.currentPage;
  }

  replaceState() {
    return {page: this.currentPage}
  }

  navigate(url) {
    const downloadingImage = new Image();
    const that = this;

    const timeout= setTimeout(function() {
      that.spinnerTarget.classList.remove("bg-opacity-0","backdrop-blur-none", "[&>svg]:hidden");
      that.spinnerTarget.classList.add("bg-opacity-70","backdrop-blur-sm", "[&>svg]:inline");
    }, 150);

    downloadingImage.onload = function(){
      that.imageTarget.src = this.src;   

      clearTimeout(timeout);

      setTimeout(function() {
        that.spinnerTarget.classList.remove("bg-opacity-70","backdrop-blur-sm", "[&>svg]:inline");
        that.spinnerTarget.classList.add("bg-opacity-0","backdrop-blur-none", "[&>svg]:hidden");
      }, 50);
    };
    downloadingImage.src = url;
    window.history.replaceState(this.replaceState(), '', this.replaceUrl());
    this.selectTarget.selectedIndex= this.currentPage - 1;
  }

  jump() {
    this.currentPage = parseInt(this.selectTarget.selectedOptions[0].value);
    this.navigate(this.url());
  }

  next() {
    this.advancePage();
    this.navigate(this.url());
  }

  prev() {
    this.recedePage()
    this.navigate(this.url());
  }
}
